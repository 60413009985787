"use client";

import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import Loader from "../Loader";
// internal

// type
type IProps = {
  spacing?: string;
  style_2?: boolean;
  style_3?: boolean;
  style_4?: boolean;
  category_2?: boolean;
  categories?: any;
};

const ShopCategory = ({
  spacing = "",
  style_2,
  style_3,
  style_4,
  category_2 = false,
  categories = [],
}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      {loading && <Loader />}
      {categories && (
        <div className={`banner__area ${spacing}`}>
          <div className={`container ${style_3 ? "custom-container" : ""}`}>
            <div
              className={`${
                !style_2 && !style_3 && !style_4
                  ? "banner__inner p-relative mt--95 z-index-1"
                  : ""
              } ${style_4 ? "banner__inner-2 p-relative" : ""}`}
            >
              <div className="row">
                {categories.slice(2, 5).map((item: any, index: any) => (
                  <div key={item?.id} className="col-xl-4 col-lg-4 col-md-6">
                    <div className="banner__item mb-30 p-relative">
                      <div className="banner__thumb fix">
                        <Link
                          href={`/shop?category=${item?.id}&price=1000000`}
                          className="w-img"
                          onClick={() => setLoading(true)}
                        >
                          <Image
                            src={item.image!}
                            alt="banner"
                            width={356}
                            height={219}
                          />
                        </Link>
                      </div>
                      <div className="banner__content p-absolute transition-3">
                        <h5>
                          <Link
                            href={`/shop?category=${item?.id}&price=1000000`}
                            dangerouslySetInnerHTML={{ __html: item.name }}
                            onClick={() => setLoading(true)}
                          ></Link>
                        </h5>
                        <Link
                          href={`/shop?category=${item?.id}&price=1000000`}
                          className="link-btn"
                          onClick={() => setLoading(true)}
                        >
                          Discover now
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {!categories && (
        <div className="banner__area-df mt-10">
          {categories.slice(3, 6).map((item: any) => (
            <div key={item.id} className="banner__item-3 mb-30">
              <div className="banner__item-3-image m-img">
                <Image
                  src={item.image!}
                  alt="banner-img"
                  width={628}
                  height={359}
                />
              </div>
              <div className="banner__content-5">
                <h5>{item.name}</h5>
                <p>{item.smDesc}</p>
                <Link
                  href={`/shop?category=${item?.categoryId}&price=1000000`}
                  className="os-btn-5"
                  onClick={() => setLoading(true)}
                >
                  Shop Now
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ShopCategory;
