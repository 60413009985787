"use client";
import { IProduct } from "@/types/product-d-t";
import { useRouter } from "next/navigation";
import { useState } from "react";
import Loader from "../Loader";
import ProductItem from "./single-product/product-item";

// props type
type IProps = {
  products: IProduct[];
  style_2?: boolean;
  container?: string;
  trendingProd?: any;
};

function TrendingProducts({
  products,
  style_2 = false,
  container = "container",
  trendingProd = [],
}: IProps) {
  const trendingProducts = products.filter((p) => p.trending);
  const [perView, setPerView] = useState<number>(style_2 ? 10 : 8);
  const [loading, setLoading] = useState<boolean>(false);

  const router = useRouter();

  const handlePerView = () => {
    // setPerView(perView + 4);
    router.push("/shop");
  };

  return (
    <>
      {loading && <Loader />}
      <section className="product__area pt-60 pb-100">
        <div className={`${container}`}>
          <div className="row">
            <div className="col-xl-12">
              <div className="section__title-wrapper text-center mb-55">
                <div className="section__title mb-10">
                  <h2>Trending Products</h2>
                </div>
                <div className="section__sub-title p-relative">
                  <p>
                    Mirum est notare quam littera gothica quam nunc putamus
                    parum claram!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            {!trendingProd && (
              <div className="row">
                {trendingProd?.map((item: any, index: any) => (
                  <div key={index} className="col-lg-3 col-md-4">
                    <ProductItem product={item} setLoading={setLoading} />
                  </div>
                ))}
              </div>
            )}
            {trendingProd && (
              <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-5">
                {trendingProd?.map((item: any, index: any) => (
                  <div key={index} className="col">
                    <ProductItem product={item} setLoading={setLoading} />
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="product__load-btn text-center mt-25">
                {10 < trendingProd.length && (
                  <a
                    onClick={() => {
                      handlePerView();
                      setLoading(true);
                    }}
                    className="os-btn os-btn-3 cursor-pointer"
                  >
                    See More
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TrendingProducts;
